import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import RightIcon from '../../images/right.svg'
import authAxios from '../../helpers/axios'
import { toast } from 'react-toastify'
import { InfoToast } from '../../helpers/toasters'
import SearchInput from '../../components/SearchInput'
import { LIMIT } from '../../helpers/constants'
import { getDateFormat, getTimeFormat, getValidDateFormat } from '../../helpers/utils'
import ManageOffer from './components/ManageOffer'

export default function Offers() {

    const [searchText,setSearchText] = useState('')
    const [offers,setOffers] = useState([])
    const [showCreate,setShowCreate] = useState({status:false,edit:null})
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(5)
    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const navigate = useNavigate()
    


    async function getOffers(){

        try 
        {
            let query = `populate=true&offset=${offset}&limit=${LIMIT}`
            if(searchText) query+= `&search=${searchText}`
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/offers?${query}`)
            console.log('data',res.data)
            setOffers(res.data)
            // setCount(res.data.totalCount)
        } catch (error) {
            toast('Error getting products')
        }
    }
    useEffect(()=>
    {
        getOffers();
    },[searchText,offset])


    async function onSubmit(e,data){
        try {
            e.preventDefault()
            let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/offers`,data)
            InfoToast('Offer created successfully')
            setShowCreate({status:false,edit:null})
        } catch (error) {
            InfoToast('Error creating offer')
        }
    }

  return (
    <div className='max-w-7xl mx-auto px-6'>
            <div className='flex justify-between items-stretch '>
        <div className='flex mb-4'>
            <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Offers'} label={'Search Offers'} />
            <div className='flex w-full items-center h-full justify-center text-center border-[#d3d3d3] bg-white rounded-md shadow-sm shadow-gray-200 ml-4'>
                <div
                    className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                    onClick={() => {
                    if (offset > 0) {
                        setOffset(offset - LIMIT);
                    }
                    }}
                >
                    <img src={RightIcon} className='w-[20px] h-[20px] rotate-180' alt='Previous Page' />
                </div>
                <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                <div
                    className='px-2 py-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                    onClick={() => {
                    if (offset + LIMIT < count) {
                        setOffset(offset + LIMIT);
                    }
                    }}
                >
                    <img src={RightIcon} className='w-[20px] h-[20px]' alt='Next Page' />
                </div>
                </div>
                <div className='ml-4'>
                    <button type='button' className='btn-md' onClick={()=>setShowCreate({status:true,edit:null})}>Add Offer</button>
                    </div>
        </div>
    </div>
        <div className='flex bg-white rounded-md flex-1  w-full'>
        <table className=' w-full'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p>Offer Code</p></td>
                    <td><p>Description</p></td>
                    <td><p>Valid From</p></td>
                    <td><p>Valid To</p></td>
                    <td><p>Offer Amount</p></td>
                    <td><p>Status</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        offers.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize'>
                                    <div>
                                         <p className='text-sm font-regular my-0'>{item.code}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.description}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                    <div>
                                         <p className='text-sm font-regular my-0'>{item.validFrom}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                         <p className='text-sm font-regular my-0'>{item.validTo}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>Rs.{item.discountValue}</p>
                                        <p className='text-xs font-regular my-0'>{item.discountType}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.isActive ? 'Active' : 'Inactive'}</p>
                                    </div>
                                </td>

                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        { showCreate.status ? <ManageOffer onClose={setShowCreate} onSubmit={onSubmit} edit={showCreate.edit}/> : null}
    </div>
  )
}

