// axiosConfig.js
import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { firebaseConfig } from '../config/auth';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'http://localhost:3030/v1'
});

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

instance.interceptors.request.use(
  async (config) => {
    // Attach Firebase token to the request
    const user = auth.currentUser;

    if (user) {
      const token = await user.getIdToken(true); // Force refresh the token
      config.headers.Authorization = `${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;